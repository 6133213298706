<template>
  <v-card class="border-radius-xl card-shadow mt-10 mx-4" v-if="asuulga">
    <h1 class="mx-4 pt-4">
      Сэдэв: <span>{{ asuulga.title }}</span>
    </h1>
    <h4 class="mx-4 text-gray">
      Сурагчийн тоо: {{ asuulga.numberOfparticipants }} /
      <span v-if="asuulga.teachers">{{ asuulga.teachers.length }}</span>
    </h4>

    <v-row v-if="asuulga" class="px-2 mt-10">
      <v-col
        cols="6"
        md="4"
        v-for="(teacher, tIndex) in teachers"
        :key="'teacher' + tIndex"
        class="mb-2"
      >
        <v-card elevation="1" :ripple="false" @click="_vote(teacher)">
          <v-card-text>
            <span class="font-weight-bold black--text">{{ tIndex + 1 }}.</span>
            {{ teacher.id }}
          </v-card-text>
          <v-card-text class="my-0 py-0">
            <span
              class="blue--text"
              v-for="(lesson, lindex) in teacher.lessons"
              :key="teacher.id + lindex"
            >
              <span v-if="lindex > 0">,</span> {{ lesson.id }}</span
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click.stop="_vote(teacher)"
              elevation="0"
              class="text--white text-lowercase"
              small
              dark
              color="red"
              v-if="teacher.answered == false"
            >
              санал өгөx
            </v-btn>
            <v-btn
              v-else
              elevation="0"
              class="text--white text-lowercase"
              small
              dark
              color="green"
            >
              санал өгсөн
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
// import XLSX from "xlsx";
Vue.use(VueSweetalert2);

const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    studentClassGroupName: null,
    teachers: null,
    asuulga: null,
    loading: false,
    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Xамрагдсан тоо",
        align: "start",
        sortable: true,
        value: "numberOfparticipants",
      },
    ],
    showStudentTimeDialog: false,
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    fb.db.doc(this.sorilId).onSnapshot((doc) => {
      let asuulga = doc.data();
      asuulga.id = doc.id;
      asuulga.ref = doc.ref;
      this.asuulga = asuulga;
      if (this.userData.role == "student") {
        this.userData["classGroup-2022"].get().then((doc) => {
          if (doc.data().fullName) {
            this.studentClassGroupName = doc.data().fullName;
            this.asuulga.ref
              .collection("teachers")
              .doc(doc.data().fullName)
              .collection("teachers")
              .get()
              .then((docs) => {
                this.teachers = [];
                docs.forEach(async (doc) => {
                  let teacher = doc.data();
                  teacher.id = doc.id;
                  teacher.ref = doc.ref;
                  var xx = await this.asuulga.ref
                    .collection("answered")
                    .doc(this.userData.id + "-" + teacher.id)
                    .get();
                  if (xx.exists) {
                    teacher.answered = true;
                  } else {
                    teacher.answered = false;
                  }

                  teacher.lessons = null;
                  teacher.ref
                    .collection("lessons")
                    .get()
                    .then((docs) => {
                      teacher.lessons = [];
                      docs.forEach((doc) => {
                        let lesson = doc.data();
                        lesson.id = doc.id;
                        lesson.ref = doc.ref;
                        console.log(lesson.id);
                        teacher.lessons.push(lesson);
                      });
                    });
                  this.teachers.push(teacher);
                });
              });
          }
        });
      }
    });
  },
  methods: {
    _getLessons(teacher) {
      var text = "";
      if (teacher.lessons) {
        for (var i = 0; i < teacher.lessons.length; i++) {
          if (i == 0) text = teacher.lessons[i].id;
          else text = text + ", " + teacher.lessons[i].id;
        }
      }
      console.log(text);
      return text;
    },
    _vote(teacher) {
      if (teacher.answered == false) {
        console.log(teacher);
        this.$router.push({
          name: "AsuulgaAnswering",
          params: {
            asuulgaRefPath: this.asuulga.ref.path,
            bulegId: this.studentClassGroupName,
            teacherName: teacher.id,
            teacherRefPath: teacher.ref.path,
            teacherLessons: this._getLessons(teacher),
          },
        });
      }
    },
    _findClassGroups(fullName) {
      var counter = 1;
      this.departments.forEach((department) => {
        for (var i = 0; i < department.classGroups.length; i++) {
          console.log(counter, department.classGroups[i].fullName);
          if (fullName == department.classGroups[i].fullName) counter++;
        }
      });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
